<template>
  <div class="branch">
    <div class="branch__flex">
      <div class="branch__item" v-for="(company, idx) in companyList" :key="idx">
        <img
          class="branch__img"
          v-if="company.attachmentList && company.attachmentList.length"
          :src="company.attachmentList[0].fileUrl"
        />
        <div class="branch__info">
          <p class="branch__name">{{ company.name }}</p>
          <div class="branch__detail">
            <p class="branch__p">地址：{{ company.address }}</p>
            <p class="branch__p">联系人：{{ company.linkMan }}</p>
            <p class="branch__p">联系电话：{{ company.linkPhone }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyList: [],
      currentCityId: null,
    };
  },
  methods: {
    groupBy(arr, fn) {
      return arr.map(typeof fn === 'function' ? fn : (val) => val[fn]).reduce((acc, val, i) => {
        acc[val] = (acc[val] || []).concat(arr[i]);
        return acc;
      }, {});
    },
    async queryCompanyList() {
      this.companyList = await this.$api.companyQueryCompanyList({});
    },
  },
  async mounted() {
    await this.queryCompanyList();
  },
};
</script>

<style scoped lang="scss">
.branch {
  width: 1100px;
  margin: 140px auto 0;
  padding: 0 0 40px;
  text-align: left;
  &__flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__item {
    width: 50%;
    padding: 0 14px 20px 0;
    display: flex;
    flex-direction: row;
    height: 185px;
    margin-bottom: 20px;
    // box-shadow: 0px 2px 10px 0px rgba(0, 70, 230, 0.1);
  }
  &__info {
    flex: 1;
    position: relative;
  }
  &__detail {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &__name {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.5;
    color: #333;
  }
  &__img {
    width: 220px;
    height: 165px;
    object-fit: cover;
    margin-right: 20px;
  }
  &__p {
    text-align: left;
    line-height: 1.5;
    color: #666;
    font-size: 15px;
  }
}

.mobile {
  .branch {
    width: 100%;
    margin: 90px 0 0;
    padding: 0 14px;
    &__flex {
      flex-direction: column;
      flex-wrap: nowrap;
    }
    &__img {
      width: 150px;
      height: 112.5px;
      margin-right: 10px;
    }
    &__item {
      width: 100%;
      height: auto;
      padding: 0;
    }
    &__name {
      font-size: 18px;
      line-height: 1.2;
      margin-bottom: 0;
    }
    &__p {
      font-size: 12px;
    }
  }
}
</style>
